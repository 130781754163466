import { Observable, pipe } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IQuestionGroups } from 'src/app/kiethiktool/interfaces/questionGroups';

import firebase from 'firebase';

@Injectable({ providedIn: 'root' })
export class QuestionGroupsAPIService {
  questionGroupFromDB$: Observable<IQuestionGroups[]>;
  unterFragenCollection: AngularFirestoreCollection;
  public questionGroupsCollectionDB: AngularFirestoreCollection;
  constructor(public db: AngularFirestore) {
    this.questionGroupsCollectionDB = this.db.collection(
      'questionGroups',
      (ref) => ref.orderBy('index', 'asc')
    );
  }

  getQuestionGroups() {
    return this.questionGroupsCollectionDB.snapshotChanges().pipe(
      map((action) => {
        return action.map((a) => {
          const id = a.payload.doc.id;
          const data = a.payload.doc.data() as IQuestionGroups;
          return {
            id,
            ...data,
          };
        });
      })
    );
    // return this.questionGroupFromDB$;
  }

  updateQuestions(id, form) {
    return this.questionGroupsCollectionDB.doc(id).update(form);
  }

  addNewUnterFrage(questionGroupId, value) {
    return this.questionGroupsCollectionDB
      .doc(questionGroupId)
      .collection('unterFragen')
      .add(value);
  }

  updateUnterFrage(questionGroupId, unterFrageID, form) {
    return this.questionGroupsCollectionDB
      .doc(questionGroupId)
      .collection('unterFragen')
      .doc(unterFrageID)
      .update(form);
  }

  removeUnterFrage(questionGroupId, unterFrageID) {
    return this.questionGroupsCollectionDB
      .doc(questionGroupId)
      .collection('unterFragen')
      .doc(unterFrageID)
      .delete();
  }

  removeAlleUnterFrage(questionGroupId) {
    return this.questionGroupsCollectionDB
      .doc(questionGroupId)
      .collection('unterFragen')
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  getUnterFrageFromID(questionGroupId) {
    return this.questionGroupsCollectionDB
      .doc(questionGroupId)
      .collection('unterFragen', (ref) => ref.orderBy('index', 'asc'))
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  

  getQuestionByID(id) {
    // return this.db
    // .collection('questionGroups', (ref) => ref.where('id', '==', id))
    // .valueChanges();
  }

  addQuestionGroups(questionGroup: any) {
    return this.questionGroupsCollectionDB.add(questionGroup);
  }

  removeQeuestionGroupsFromDB(id: string) {
    return this.questionGroupsCollectionDB.doc(id).delete();
  }

  editQuestionGroups(id: string, questionGroups: IQuestionGroups) {
    return this.questionGroupsCollectionDB.doc(id).update(questionGroups);
  }

  getQG() {
    // const snapshot  = await firebase.firestore().collection('questionGroups').get()
    // return snapshot.docs.map(doc => doc.data());

    // const events = await firebase.firestore().collection('questionGroups')
    // events.get().then((querySnapshot) => {
    //     const tempDoc = querySnapshot.docs.map((doc) => {
    //       return { id: doc.id, ...doc.data() }
    //     })
    //     console.log(tempDoc)
    //   })

    const markers = [];
    
     firebase
      .firestore()
      .collection('questionGroups')
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
         
          markers.push(doc.data());
          

        });
      

        
      });
     return markers.length;
      
      
    


    // const snapshot = await firebase.firestore().collection('questionGroups').get()
    // return snapshot.docs.reduce(function (acc, doc, i) {
    //           acc[i] = doc.data();
    //           return acc;
    //         }, {});


  }
}
