import { UsersState } from 'src/app/kiethiktool/state/user/user.state';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {RouterModule} from '@angular/router';
//import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
//import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { NgxsModule } from '@ngxs/store';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { HelpModalComponent } from './kiethiktool/components/help-modal/help-modal.component';
import { QuestionState } from './kiethiktool/state/question/question.state';
import { UnterFragenState } from './kiethiktool/state/unterFragen/unterFragen.state';
import { IonicStorageModule } from "@ionic/storage-angular";
import * as cordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import { Drivers } from "@ionic/storage";
import { AuthGuard } from './services/user/auth.guard';



@NgModule({
  declarations: [AppComponent, HelpModalComponent],
  entryComponents: [],
  imports:[
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    IonicStorageModule.forRoot({
      name: 'kiethiktool',
      driverOrder: [cordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]}),
    IonicModule.forRoot(),
    RouterModule,
    



    //NgxsReduxDevtoolsPluginModule.forRoot(),


    NgxsModule.forRoot([UsersState, QuestionState,UnterFragenState], {
      developmentMode: !environment.production
    }),
    //NgxsLoggerPluginModule.forRoot(),
    NoopAnimationsModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AuthGuard],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ],
})
export class AppModule {}
