import { Userservice } from './../../users/user.service';
import { Observable, Observer, from, throwError } from 'rxjs';
import { IUsers } from 'src/app/kiethiktool/interfaces/users';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { StorageServiceService } from '../storageServices/storage-service.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserAPIService {
  usersFormDB$: Observable<IUsers[]>;
  public userCollectionDB: AngularFirestoreCollection;
  constructor(
    public db: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
    private userservice: Userservice,
    private afAuth: AngularFireAuth,
    private storageService: StorageServiceService,
  ) {
    this.userCollectionDB = this.db.collection('users', (ref) =>
      ref.orderBy('registratedOn', 'asc')
    );
  }

  getUsers() {
    this.usersFormDB$ = this.userCollectionDB.snapshotChanges().pipe(
      map((action) => {
        return action.map((a) => {
          const id = a.payload.doc.id;
          const data = a.payload.doc.data() as IUsers;
          return { id, ...data };
        });
      })
    );

    return this.usersFormDB$;
  }

  async getUsers2() {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .orderBy('registratedOn', 'asc')
      .get();
    return snapshot.docs.map((doc) => doc.data());
  }

  async getUsers3() {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .orderBy('registratedOn', 'desc')
      .get();
    const collection = [];
    snapshot.forEach((doc) => {
      //collection[doc.id] = doc.data();
      collection.push({ id: doc.id, ...doc.data() });
    });
    return collection;
  }

  getUserById(id) {
    return this.userCollectionDB.doc(id).valueChanges();
  }

  getUserCurrentById() {
    return this.userCollectionDB.doc(this.getUserUID()).valueChanges();
  }

  addUser(user: IUsers) {
    return this.userCollectionDB.add(user);
  }

  removeUserFromDB(id: string) {
    return this.userCollectionDB.doc(id).delete();
  }

  editUser(id: string, user: any) {
    return this.userCollectionDB.doc(id).update(user);
  }

  editCurrentUser( user: any) {
    return this.userCollectionDB.doc(this.getUserUID()).update(user);
  }

  getUserUID() {
    // hier ist das user ID welche für db benutzt wird

    const auth = firebase.auth();

    return auth.currentUser.uid;
  }

  getCurrentUser() {
    setTimeout(() => {
      this.userservice
        .getUserId()
        .pipe(take(1))
        .subscribe((res2) => {
          return this.userCollectionDB.doc(res2).valueChanges();
        });
    }, 500);

    // return this.userservice.getUserId();
    //console.log("danach", id);

    // return this.userCollectionDB.doc(this.getUserUID()).valueChanges();
  }

  

  addProduct(value) {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('product')
      .add(value);
  }

  addSendToHelpProduct(value) {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('sendHelpProduct')
      .add(value);
  }

  updateSendToHelpProduct(userID,helpID,value) {
    return this.userCollectionDB
      .doc(userID)
      .collection('sendHelpProduct')
      .doc(helpID)
      .update(value);
  }

  updateSendToHelpProduct2(helpID,value) {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('sendHelpProduct')
      .doc(helpID)
      .update(value);
  }

  getCurrentUserSendHelpProduct() {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('sendHelpProduct')
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  addHelpProduct(id,value) {
    return this.userCollectionDB
      .doc(id)
      .collection('helpProduct')
      .add(value);
  }

  getUserhelpProduct(id) {
    return this.userCollectionDB
      .doc(id)
      .collection('helpProduct')
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  getCurrentUserhelpProduct() {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('helpProduct')
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  removeHelpProduct(id,productID) {
    return this.userCollectionDB
      .doc(id)
      .collection('helpProduct')
      .doc(productID)
      .delete();
  }

  removeSendHelpProduct(productID) {
    this.userCollectionDB
      .doc(this.getUserUID())
      .collection('sendHelpProduct')
      .doc(productID)
      .delete();
  }

  updateHelpProduct(productID,value) {
    
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('helpProduct')
      .doc(productID)
      .update(value);
  }

  

  addProductAnswer(productID, questionID, value) {
    
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('product')
      .doc(productID)
      .collection('answers')
      .doc(questionID)
      .set(value);
  }

  addProductAnswerByID(id, productID, questionID, value) {
    
    return this.userCollectionDB
      .doc(id)
      .collection('product')
      .doc(productID)
      .collection('answers')
      .doc(questionID)
      .set(value);
  }

  updateProductAnswer(productID, questionID, value) {
    
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('product')
      .doc(productID)
      .collection('answers')
      .doc(questionID)
      .update(value);
  }

  updateProductAnswerToUser(userID,productID, questionID, value) {
    
    return this.userCollectionDB
      .doc(userID)
      .collection('product')
      .doc(productID)
      .collection('answers')
      .doc(questionID)
      .update(value);
  }

  

 

  getUserProduct() {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('product')
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  getAnyUserAllProduct(id) {
    return this.userCollectionDB
      .doc(id)
      .collection('product')
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }
  getAnyUserOneProduct(id, productID) {
    return this.userCollectionDB
      .doc(id)
      .collection('product')
      .doc(productID)
      .collection('answers')
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }
  getUserAnswers(productID) {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('product')
      .doc(productID)
      .collection('answers')
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((a) => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }
  removeProduct(productID) {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('product')
      .doc(productID)
      .delete();
  }

  removeAlleUnterFrageAnswers(productID) {
    return this.userCollectionDB
      .doc(this.getUserUID())
      .collection('product')
      .doc(productID)
      .collection('answers')
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  // removeAlleProduct() {
  //   return this.userCollectionDB
  //     .doc(this.getUserUID())
  //     .collection('product')
  //     .get()
  //     .toPromise()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         doc.ref.delete();
  //       });
  //     });
  // }


  // deleteAccount(password: string): Observable<boolean> {
  //   return new Observable((observer: Observer<boolean>) => {
  //     const user = this.afAuth.currentUser;
  //     user.then((userData) => {
  //       const credential = firebase.auth.EmailAuthProvider.credential(userData.email, password);
  //       userData.reauthenticateWithCredential(credential)
  //       .then(() => {
           
  //           userData.delete()
  //             .then(() => {
          
             
  //               console.log('Account erfolgreich gelöscht.');
  //               observer.next(true); // Erfolgswert emittieren
  //               observer.complete();
  //             })
  //             .catch((error) => {
  //               console.log('Fehler beim Löschen des Accounts:', error);
  //               observer.next(false); // Fehlerwert emittieren
  //               observer.complete();
  //             });
  //         })
  //         .catch((error) => {
  //           console.log('Fehler bei der erneuten Authentifizierung:', error);
  //           observer.next(false); // Fehlerwert emittieren
  //           observer.complete();
  //         });
  //     });
  //   });
  // }

  deleteAccount( password: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const user = this.afAuth.currentUser;
      user.then((userData) => {
        const credential = firebase.auth.EmailAuthProvider.credential(userData.email, password);
        userData.reauthenticateWithCredential(credential)
          .then(() => {
            this.storageService.get('userData').then((user) => {
              this.userCollectionDB.doc(this.getUserUID()).delete()
                .then(() => {
                  console.log('Aktuellen Benutzer aus der Datenbank entfernt.');
                  userData.delete()
                    .then(() => {
                      console.log('Account erfolgreich gelöscht.');
                      observer.next(true); // Erfolgswert emittieren
                      observer.complete();
                    })
                    .catch((error) => {
                      console.log('Fehler beim Löschen des Accounts:', error);
                      observer.next(false); // Fehlerwert emittieren
                      observer.complete();
                    });
                })
                .catch((error) => {
                  console.log('Fehler beim Entfernen des Benutzers aus der Datenbank:', error);
                  observer.next(false); // Fehlerwert emittieren
                  observer.complete();
                });
            }).catch((error) => {
              console.log('Fehler beim Abrufen der Benutzerdaten:', error);
              observer.next(false); // Fehlerwert emittieren
              observer.complete();
            });
          })
          .catch((error) => {
            console.log('Fehler bei der erneuten Authentifizierung:', error);
            observer.next(false); // Fehlerwert emittieren
            observer.complete();
          });
      }).catch((error) => {
        console.log('Fehler beim Abrufen des aktuellen Benutzers:', error);
        observer.next(false); // Fehlerwert emittieren
        observer.complete();
      });
    });
  }

  adminDeleteAccountFromDB(password: string, id:string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const currentUser = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
  
      currentUser.reauthenticateWithCredential(credential)
        .then(() => {
          const userId = id;
  
          // Überprüfen, ob der zu löschende Benutzer der aktuell angemeldete Benutzer ist
          if (userId === currentUser.uid) {
            console.log('Der aktuell angemeldete Benutzer kann sich nicht selbst löschen.');
            observer.next(false);
            observer.complete();
            return;
          }
  
          // Benutzer aus der Firestore-Datenbank löschen
          this.removeUserFromDB2(userId)
            .then(() => {
              console.log(`Benutzer ${userId} erfolgreich aus der Datenbank gelöscht.`);
  
              // Firebase-Authentifizierungsaccount löschen
             
              
               
            })
            .catch((error) => {
              console.log(`Fehler beim Löschen des Benutzers ${userId} aus der Datenbank: ${error}`);
              observer.next(false);
              observer.complete();
            });
        })
        .catch((error) => {
          console.log('Fehler bei der erneuten Authentifizierung:', error);
          observer.next(false);
          observer.complete();
        });
    });
  }
  
  
  
  removeUserFromDB2(id: string): Promise<void> {
    return this.userCollectionDB.doc(id).delete();
  }
  
  
  












}
