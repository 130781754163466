import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageServiceService {
  private _storage: Storage | null = null;
  private storageLocal;
  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  async set(key: string, value: any) {
    this._storage,
      (this.storageLocal = {
        key: value,
      });
    return this._storage?.set(key, value);
  }
  async get(key: string) {
    return this._storage?.get(key);
  }

  async clear() {
    this.storage.clear();
    this.storage.remove('unterFragen');
    this.storage.remove('questionGroups');
    this.storage.remove('userData');
    this.storage.remove('userAnswers');
    this.storage.remove('worktogether');
    this.storage.remove('productFromUser');
    this.storage.remove('startpage');
    
    this._storage.clear();
    this._storage.remove('unterFragen');
    this._storage.remove('questionGroups');
    this._storage.remove('userData');
    this._storage.remove('userAnswers');
    this._storage.remove('worktogether');
    this._storage.remove('productFromUser');
    this._storage.remove('startpage');
  }

   async clearAnswers(){
    this.storage.remove('userAnswers');
    this._storage.remove('userAnswers');
  }

  async clearAnswersProductFromUser(){
    this.storage.remove('productFromUser');
    this._storage.remove('productFromUser');
  }

  async clearAnswersWorktogether(){
    this.storage.remove('worktogether');
    this._storage.remove('worktogether');
  }

  
}
