import { AddUnterFragen } from './../../../kiethiktool/state/unterFragen/unterFragen.action';
import { AddQuestion } from '../../../kiethiktool/state/question/question.action';
import { UsersState } from './../../../kiethiktool/state/user/user.state';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
//import { resolve } from 'url';

import { AngularFirestore } from '@angular/fire/firestore';
import { getValue, Store } from '@ngxs/store';
//import * as firebase from 'firebase/app';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase';

import { SetUserId } from 'src/app/kiethiktool/state/user/user.action';
import { UserAPIService } from '../UsersApi/userApi.service';
import { take } from 'rxjs/operators';
import { Userservice } from '../../users/user.service';
import { QuestionGroupsAPIService } from '../QuestionGroups/questionGroups.service';
import { Observable } from 'rxjs';
import { QuestionState } from 'src/app/kiethiktool/state/question/question.state';
import { UnterFragenState } from 'src/app/kiethiktool/state/unterFragen/unterFragen.state';
import { StorageServiceService } from '../storageServices/storage-service.service';
import { Router } from '@angular/router';
import { AuthService } from '../../user/auth.service';
import { AlertController } from '@ionic/angular';


//require('firebase/auth');

//import { environment } from 'src/environments/environment';
//import { IAuthentication } from 'src/app/kiethiktool/interfaces/authentication';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userCollection: any;
  userData: any;
  myIdist;
  questionsID: Observable<any> | any;

  constructor(
    private firebaseAuth: AngularFireAuth,
    private db: AngularFirestore, // public storage: Storage,
    private stateService: UsersState,
    private readonly store: Store,
    private userService: UserAPIService,
    private userFacadeStateService: Userservice,
    private questionsGroupService: QuestionGroupsAPIService,
    private storageService: StorageServiceService,
    private route: Router,
    private authService: AuthService,
    private alertController: AlertController,
   
   
  ) {
    this.userCollection = db.collection('users');

    // this.firebaseAuth.authState.subscribe( user => {

    //   if (user) {

    //     this.userData=user;

    //   } else {
    //     this.userData = null;
    //   }
    // });
  }

  registerUser(value, companyID, code) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseAuth
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            let userData = {
              companyname: value.companyname,
              firstname: value.fname,
              lastname: value.lname,
              email: value.email,
              registratedOn: new Date(),
              role: 'user',
              companyID: companyID,
              code:code
            };
            this.userCollection.doc(res.user.uid).set(userData);
            res.user.sendEmailVerification();
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  resetPass(email) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseAuth.sendPasswordResetEmail(email).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  loginUser(value) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseAuth
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            // setTimeout(() => { console.log("jaaa");
            //  this.addQuestionAndUnterFragen();}, 10000);
            //this.userFacadeStateService.setUserIDToState(res.user.uid);
              this.addQuestionAndUnterFragen();
              this.userService
              .getUserById(res.user.uid)
              .pipe(take(1))
              .subscribe(async (res2) => {
                this.userFacadeStateService.setUserDataToState(res2);
                if(res2==undefined || res2==null){
                  this.presentAlertSave();
                }
                this.authService.signIn(res2['role']);

               
                
                await this.storageService.set('userData', res2);
                //this.appComponent.setUserIsLoggedIn(true);
                
              });
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  async presentAlertSave() {
    const alert = await this.alertController.create({
      header: "Der Benutzer wurde gelöscht",
      message: "Bitte nehmen Sie Kontakt mit dem Administrator auf, damit Ihr Account entsperrt wird: admin@ethik-ki-tool.com",
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.route.navigate(['/login']);
          }
        }
      ]
    });
  
    await alert.present();
  }

  addQuestionAndUnterFragen() {
    this.questionsGroupService
      .getQuestionGroups()
      .pipe(take(1))
      .subscribe(async (questionGroups) => {
        if (questionGroups) {
          await this.storageService.set('questionGroups', questionGroups);
        }

        for (let i = 0; i < questionGroups.length; i++) {
          this.store.dispatch(new AddQuestion(questionGroups[i]));

          this.questionsGroupService
            .getUnterFrageFromID(questionGroups[i].id)
            .pipe(take(1))
            .subscribe((unterFrage) => {
              const unterFrageObject = {
                unterFrage: unterFrage,
                id: questionGroups[i].id,
              };

              this.store.dispatch(new AddUnterFragen(unterFrageObject));
            });
        }

        this.store
          .select(UnterFragenState.getUnterFragen)
          .subscribe(async (res) => {
            if (res) {
              
              
              await this.storageService.set('unterFragen', res);
              
            }
          });
      });
  }

  checkEmailVerified() {
    return this.firebaseAuth.authState;
  }

  logoutUser() {
  
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.firebaseAuth
          .signOut()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject();
          });
      }
      //this.userFacadeStateService.setUserDataToClear();
    });
  }

  userDetails() {
    return firebase.auth().currentUser;
  }

  sendVerify() {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then
        // res => resolve(res),
        // err => reject(err)
        ();
    });
  }

  checkVerify() {
    return this.userData.emailVerified;

    // const user = this.userData;
    // return ( user !== null && user.emailVerified !== false );

    //   const user = this.userData;
    //   if(user === null || user === undefined) return user.emailVerified
    //   console.log(user.emailVerified);
    //   return user.emailVerified;
  }

  getUserEmail() {
    return firebase.auth().currentUser.email;
  }

  isLogedin() {
    return this.firebaseAuth.onAuthStateChanged;
  }

  //  isLoggedIn(): boolean {
  //   const user = firebase.auth().currentUser;

  //   //return (user !== null && user.emailVerified !== false && user !== undefined) ? true : false;
  //   return  user !== null;
  //}

  getAccessToken() {
    // hier ist das user ID welche für db benutzt wird
    return firebase.auth().currentUser.uid;
  }

  // changePassword(email,oldPassword, newPassword){
  //   firebase.auth()
  //       .signInWithEmailAndPassword(email, oldPassword)
  //       .then(function(user) {

  //           firebase.auth().currentUser.updatePassword(newPassword).then(function(){

  //               //Do something

  //           }).catch(function(err){
  //               //Do something
  //           });

  //       }).catch(function(err){
  //           //Do something
  //       });
  // }

  changePassword(email) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(
        () => {
          // success, show some message
        },
        (err) => {
          // handle errors
        }
      );
  }

  changeEmail(email) {
    firebase
      .auth()
      .currentUser.updateEmail(email)
      .then(
        () => {
          // success, show some message
        },
        (err) => {
          // handle errors
        }
      );
  }

  // setUserID(id) {
  //   this.storage.set('userID', id);
  // }

  //   async getUserID() {
  //    return await this.storage.get('userID');
  //   }
}
