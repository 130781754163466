import { IUsers } from "../../interfaces/users";

export class AddToListUsers {
    static readonly type='[Users] es muss ein Typ User Objekt zur Liste hinzugefügt werden';
    constructor(public users: IUsers){}
}
export class SetUserId {
    static readonly type='[Users] es muss ein Typ User Objekt zur Liste hinzugefügt werden';
    constructor(public userId: string | number){}
}

export class SetUserData {
    static readonly type='[UserData] es muss ein User Objekthinzugefügt werden';
    constructor(public userData: any){}
}

// export class SetUserDataToClear {
//     static readonly type='[UserData] es muss ein User Objekthinzugefügt werden';
//     constructor(){}
// }