import { IUsers } from "../../interfaces/users";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddToListUsers, SetUserData, SetUserId } from "./user.action";
import produce from "immer";

export const USER_STATE_NAME = "users";
export interface IUsersState {
  listeUsers: IUsers[];
  userId: string | number;
  userData: any;
}

export function initialState(): IUsersState {
  return {
    listeUsers: [],
    userId: '',
    userData: undefined
  };
}

@State<IUsersState>({
  name: USER_STATE_NAME,
  defaults: initialState(),
})
@Injectable()
export class UsersState {
    @Selector()
    static getListUsers(usersListe: IUsersState): IUsers[]{
        return usersListe.listeUsers;
    }

    @Selector()
    static getUserId(usersListe: IUsersState): any{
        return usersListe.userId;
    }

    @Selector()
    static getUserData(userData: IUsersState): any{
        return userData.userData;
    }

    @Action(AddToListUsers)
    addToListUsers({getState, setState}: StateContext<IUsersState>, action:AddToListUsers){
        setState(produce(getState(), usersDraft=>{
          usersDraft.listeUsers.push(action.users);
        }))
    }

    @Action(SetUserId)
    setUserId({getState, setState}: StateContext<IUsersState>, action:SetUserId){
        setState(produce(getState(), usersDraft=>{
          usersDraft.userId = action.userId;
        }))
    }

    @Action(SetUserData)
    setUserData({getState, setState}: StateContext<IUsersState>, action:SetUserData){
        setState(produce(getState(), usersDraft=>{
          usersDraft.userData = action.userData;
        }))
    }

    // @Action(SetUserDataToClear)
    // setUserDataToClear({getState, setState}: StateContext<IUsersState>, action:SetUserDataToClear){
    //     setState(produce(getState(), usersDraft=>{
    //       usersDraft.userData = undefined;
    //       usersDraft.userId = undefined;
    //       usersDraft.listeUsers = undefined;
    //     }))
    // }
}


