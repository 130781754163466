import { Userservice } from './services/users/user.service';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './services/API/AuthenticationApi/authenticationApi.service';
import { UserAPIService } from './services/API/UsersApi/userApi.service';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { StorageServiceService } from './services/API/storageServices/storage-service.service';
import { MenuController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  // public appPages = [
  //   { title: 'Fragen beantworten', url: 'answer-questions', icon: 'help' },
  //   { title: 'Fragengruppe hinzufügen', url: 'question-groups', icon: 'albums' },
  //   { title: 'Frage hinzufügen', url: 'add-question', icon: 'add' },
  //   { title: 'Fragen bearbeiten', url: 'edit-questions', icon: 'construct' },

  // ];
  destroy$: Subject<void> = new Subject<void>();

  public appPages = [];
  public adminPages = [];
  private urlArray = [];
  userIsLoggedIn: boolean;
  clientHeight: number;
  @ViewChild('headerButton', { static: false })
  answerQuestion: ElementRef<HTMLElement>;
  @ViewChild('worktogether', { static: false })
  workTogether: ElementRef<HTMLElement>;
  //public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    private authService: AuthenticationService,
    private userAPIService: UserAPIService,
    private userService: Userservice,
    private storageService: StorageServiceService,
    private renderer: Renderer2,
    private menuCtrl: MenuController,
    private route: Router,
    


    @Inject(DOCUMENT) private _document,

  ) {
 
    this.clientHeight = window.innerHeight;


  }

  

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ionViewWillEnter() {
    this.storageService.get('userData').then((user) => {
      this.userIsLoggedIn = user != undefined;
      console.log(user);

      if(user){

        if(this.userISLoggedIn){

          if(user['role']=="admin"){
            this.menuCtrl.enable(true);

          }
          if(user['role']=="user"){
           this.menuCtrl.enable(true);

          }
        }

      }

    });


  }



  userISLoggedIn() {
    this.storageService.get('userData').then((user) => {
      this.userIsLoggedIn = user != undefined;

      if(user){
        this.menuOpen();
        this.menuCtrl.enable(true);

        //window.location.reload();
        this.storageService.get('unterFragen').then((unterFragen)=>{
          if(unterFragen){
            //this.route.navigate(['worktogether']);

             window.location.reload();
          }
        });
        // if(this.userISLoggedIn){
        //   if(user['role']=="admin"){
        //     //window.location.reload();


        //   }
        //   if(user['role']=="user"){
        //    // window.location.reload();
        //   }

        // }

      }


    });
  }

  resetHeader(){
    this.appPages.forEach(items => {

        document.getElementById(items.url).classList.remove('active-button-blue');
        document.getElementById(items.url).classList.remove('active-button-orange');

    });

  }

  setUserIsLoggedIn(userIsLoggedIn: boolean) {
    this.userIsLoggedIn = userIsLoggedIn;

    if(this.userIsLoggedIn){
      this.menuCtrl.enable(true);
      this.userISLoggedIn();

    }


  }

  checkArrayForDup(arrayWithDups) {
    let uniqueMergedArray = [];
    arrayWithDups.filter((element) => {
      const isDuplicate = uniqueMergedArray.includes(element);

      if (!isDuplicate) {
        uniqueMergedArray.push(element);
        return true;
      }
      return false;
    });
    return uniqueMergedArray;
  }

  addAndRemoveClass(url, index) {
    this.urlArray.forEach((element) => {
      if (element == url) {
        if (index % 2 === 0) {
          document.getElementById(url).classList.add('active-button-blue');
        } else {
          document.getElementById(url).classList.add('active-button-orange');
        }
      } else {
        this.appPages.forEach(items => {
          if(items.url == element){
            document.getElementById(element).classList.remove('active-button-blue');
            document.getElementById(element).classList.remove('active-button-orange');
          }
        });
      }
    });
  }

  switchButtonColor(index, url) {
    if (!this.urlArray.includes(url) ) {
      this.urlArray.push(url);
    }


    this.urlArray.forEach((element) => {
      switch (url) {
        case element:
          this.addAndRemoveClass(url, index);
          break;

        default:
          break;
      }

    });
  }

  menuOpen() {




    this.urlArray.push(window.location.href.toString().split(window.location.host + "/")[1])
    const url = window.location.href.toString().split(window.location.host + "/")[1]


    setTimeout(() => {
      switch (url) {
        // case "answer-questions":
        //   this.addAndRemoveClass(url, 0)
        //   break;
        case "worktogether":
          this.addAndRemoveClass(url, 1)
          break;
        case "help-question":
          this.addAndRemoveClass(url, 2)
          break;
        case "sent-questions":
          this.addAndRemoveClass(url, 3)
          break;
        case "edit-profile":
          this.addAndRemoveClass(url, 4)
          break;
        default:
          break;
      }

    }, 500);
    this.storageService.get('userData').then((res) => {
      this.userIsLoggedIn = res != undefined;



      // this.showAdminPanels(res['role']);
      try {
        if (res['role'] == 'admin') {
          this.menuCtrl.enable(true);
          this.setMenuItemForAdmin();
        } else if (res['role'] == undefined || res['role'] == 'user') {
          this.menuCtrl.enable(true);


          this.setMenuItemForElse(res);

        } else {
         this.menuCtrl.enable(true);
          this.setMenuItemForElse(res);
          console.log("a");

        }
        //thats dirty
      } catch {
        this.menuCtrl.enable(false);

        this.setMenuItemForElse(res);
      }
    });
  }

  ngOnInit() {
    this.storageService.get('userData').then((user) => {
      this.userIsLoggedIn = user != undefined;


      if(user){
        if(this.userISLoggedIn){



          if(user['role']=="admin"){
            this.menuCtrl.enable(true);


          }

        }


      }
    });
  }

  ngAfterViewInit() {


    setTimeout(() => {
      this.menuOpen();

    }, 1000);
    this.storageService.get('userData').then((user) => {
      this.userIsLoggedIn = user != undefined;

      if(user){
        if(this.userISLoggedIn){

          if(user['role']=="admin"){
            this.menuCtrl.enable(true);



          }

        }


      }
    });
  }

  logout() {
    this.authService.logoutUser();
    this.storageService.clear();
    this.userIsLoggedIn = false;
  }




  setMenuItemForNothing() {
    this.appPages = [];
  }

  setMenuItemForAdmin() {
    this.appPages = [
    //  { title: 'Ihre Produkte', url: 'answer-questions', icon: 'help' },
      { title: 'KI-Lösung', url: 'worktogether', icon: 'hammer' },
      { title: 'Bearbeitungs-Status', url: 'help-question', icon: 'information' },
      {
        title: 'Gesendete Fragen',
        url: 'sent-questions',
        icon: 'cloud-upload',
      },
      {
        title: 'Profil bearbeiten',
        url: 'edit-profile',
        icon: 'person-circle',
      },
      {
        title: 'Terminologie',
        url: 'glossar-definitions',
        icon: 'book',
      },
    ];

    this.adminPages = [
      { title: 'Statusbericht', url: 'report', icon: 'book' },
      { title: 'Homepage bearbeiten', url: 'edit-frontpage', icon: 'color-wand' },
      {
        title: 'Unternehmen freischalten',
        url: 'unlock-company',
        icon: 'business',
      },
      { title: 'Fragen bearbeiten', url: 'edit-questions', icon: 'construct' },
      { title: 'Frage hinzufügen', url: 'add-question', icon: 'add' },
      {
        title: 'Fragengruppe hinzufügen',
        url: 'question-groups',
        icon: 'albums',
      },
    ];
  }

  setMenuItemForElse(user) {
    const helper = [
      { title: 'KI-Lösung', url: 'worktogether', icon: 'hammer' },
      { title: 'Bearbeitungs-Status', url: 'help-question', icon: 'information' },
      // { title: 'Helfen', url: 'help-question', icon: 'information' },
      {
        title: 'Gesendete Fragen',
        url: 'sent-questions',
        icon: 'cloud-upload',
      },
      {
        title: 'Profil bearbeiten',
        url: 'edit-profile',
        icon: 'person-circle',
      },
      {
        title: 'Terminologie',
        url: 'glossar-definitions',
        icon: 'book',
      },
    ];
    this.appPages = helper

    // if(user && (user.companyID.length == 0 || user.companyID == undefined)){
    //   this.appPages = helper.filter(element => element.url != "worktogether")
    // }else{
    //   this.appPages = helper
    // }
  }




  
}
