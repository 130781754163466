import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from 'src/environments/environment';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private alertCtrl: AlertController
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const expectedRole = next.data?.role || null;

    return new Promise((resolve, reject) => {
      if (!firebase.apps.length) {
        firebase
          .initializeApp(environment.firebaseConfig)
          .auth()
          .onAuthStateChanged((user: firebase.User) => {
            
            if (user) {
              resolve(true);
              this.checkRole(expectedRole);
            } else {
              this.router.navigate(['login']);
              resolve(false);
            }
          });
      }
      // if already initialized, use that one
      else {
        firebase
          .app()
          .auth()
          .onAuthStateChanged((user: firebase.User) => {
            if (user) {
              resolve(true);
              this.checkRole(expectedRole);
            } else {
              this.router.navigate(['login']);
              resolve(false);
            }
          });
      }
    });
  }

  checkRole(expectedRole) {
    this.authService.loadUser();
    this.authService
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        if (expectedRole) {
          if (user) {

            
            if (user['value'] == expectedRole) {
              return true;
            } else {
              this.showAlert();
              this.router.navigate(['worktogether']);
            }
          }
        }
      });
  }

  async showAlert() {
    let alert = await this.alertCtrl.create({
      header: 'Unauthorized',
      message: 'You are not authorized to visit that page!',
      buttons: ['OK'],
    });
    alert.present();
  }
}
