import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddUnterFragen } from "./unterFragen.action";


export class UnterFragenStateModel{
    unterFragen: any[]| any;
    

}
@State<UnterFragenStateModel>({
    name: 'unterFrage',
    defaults:{
        unterFragen:[],
        
    },
})

export class UnterFragenState{
    @Selector()
    static getUnterFragen(state:UnterFragenStateModel ){
        return state.unterFragen
    }

    @Action(AddUnterFragen)
    add({getState, patchState}: StateContext<UnterFragenStateModel>, {payload}: AddUnterFragen){
        const state= getState();
        patchState({
            unterFragen:[...state.unterFragen, payload]
        });
    }
}