import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import { Storage } from "@ionic/storage-angular";
import { Router } from '@angular/router';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
 

 
const TOKEN_KEY:string = 'user-token';
 
export interface User {

  role: string;
  permissions: string[];
}
 
@Injectable({
  providedIn: 'root'
})
export class AuthService {
    

      user: Observable<any>;
      authState= new BehaviorSubject(null);
      secureStorage = SecureStoragePlugin;

  constructor(private router: Router, private storage: Storage) {
    this.loadUser();
    this.user=this.authState.asObservable().pipe(filter(response=>response));
  }

 
  
 
  signIn(name): Observable<any> {
    
    
    // Local Dummy check, usually server request!
    let userObj: User;
 
    if (name === 'user') {
      userObj = {
        
        role: 'USER',
        permissions: ['read']
      };
    } else if (name === 'admin') {
      userObj = {
       
        role: 'ADMIN',
        permissions: ['read', 'write']
      };
      
    }
   
    
 
    
    //this._storage.set('myData', userObj);
    // this.myData.next(userObj);
    this.authState.next(userObj);
    this.secureStorage.set({key:TOKEN_KEY, value:userObj['role'] });
    this.loadUser();
    return of(userObj);
  
  }

  loadUser(){
    this.secureStorage.get({key:TOKEN_KEY}).then((user)=>{
     
      
    
      
      if(user){
        this.authState.next(user);
      }else{
        let userObj: User;
        userObj = {
         
          role: 'USER',
          permissions: ['read']
        };

        this.authState.next(userObj)

      }
    })
  }
 
  // Access the current user
  getUser() {
    this.loadUser();
    return this.authState.asObservable().pipe(filter(response=>response));
  }

  async signOut(){
    await this.secureStorage.set({key:TOKEN_KEY, value:null});
    await this.secureStorage.clear();
    this.authState.next(null);

  }
 

}