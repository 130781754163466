import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


import { AuthGuard } from './services/user/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./kiethiktool/components/folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./kiethiktool/containers/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./kiethiktool/containers/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'complete-registration',
    loadChildren: () => import('./kiethiktool/containers/complete-registration/complete-registration.module').then( m => m.CompleteRegistrationPageModule)
  },
  // {
  //   path: 'answer-questions',
  //   loadChildren: () => import('./kiethiktool/components/answer-questions/answer-questions.module').then( m => m.AnswerQuestionsPageModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'question-groups',
    loadChildren: () => import('./kiethiktool/components/question-groups/question-groups.module').then( m => m.QuestionGroupsPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'add-question',
    loadChildren: () => import('./kiethiktool/components/add-question/add-question.module').then( m => m.AddQuestionPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'edit-questions',
    loadChildren: () => import('./kiethiktool/components/edit-questions/edit-questions.module').then( m => m.EditQuestionsPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'change-question-group',
    loadChildren: () => import('./kiethiktool/components/edit-questions/components/change-question-group/change-question-group.module').then( m => m.ChangeQuestionGroupPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./kiethiktool/components/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    loadChildren: () => import('./kiethiktool/components/edit-profile/components/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'change-email',
    loadChildren: () => import('./kiethiktool/components/edit-profile/components/change-email/change-email.module').then( m => m.ChangeEmailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./kiethiktool/containers/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./kiethiktool/components/report/report.module').then( m => m.ReportPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'help-question',
    loadChildren: () => import('./kiethiktool/components/help-question/help-question.module').then( m => m.HelpQuestionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sent-questions',
    loadChildren: () => import('./kiethiktool/components/sent-questions/sent-questions.module').then( m => m.SentQuestionsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'modal-page',
    loadChildren: () => import('./kiethiktool/components/modal-page/modal-page.module').then( m => m.ModalPagePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unlock-company',
    loadChildren: () => import('./kiethiktool/components//unlock-company/unlock-company.module').then( m => m.UnlockCompanyPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'worktogether',
    loadChildren: () => import('./kiethiktool/components/worktogether/worktogether.module').then( m => m.WorktogetherPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'worktogether-add-product-modal',
    loadChildren: () => import('./kiethiktool/components/worktogether-add-product-modal/worktogether-add-product-modal.module').then( m => m.WorktogetherAddProductModalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'product-from-user',
    loadChildren: () => import('./kiethiktool/components/product-from-user/product-from-user.module').then( m => m.ProductFromUserPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then( m => m.ImpressumPageModule)
  },


{
  path: 'glossar',
  loadChildren: () => import('./glossar/glossar.module').then( m => m.GlossarPageModule)
},
  {
    path: 'aboutus',
    loadChildren: () => import('./kiethiktool/containers/aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'shared',
    loadChildren: () => import('./shared/shared.module').then( m => m.SharedPageModule)
  },
  {
    path: 'edit-frontpage',
    loadChildren: () => import('./kiethiktool/components/edit-frontpage/edit-frontpage.module').then( m => m.EditFrontpagePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'glossar-definitions',
    loadChildren: () => import('./shared/components/terminologie/glossar-definitions.module').then( m => m.GlossarDefinitionsPageModule)
  },

  {
    path: 'delete-account',
    loadChildren: () => import('./delete-account/delete-account.module').then( m => m.DeleteAccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admindeleteuserfromdb',
    loadChildren: () => import('./admindeleteuserfromdb/admindeleteuserfromdb.module').then( m => m.AdmindeleteuserfromdbPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  
  { path: '**', redirectTo: 'worktogether' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
