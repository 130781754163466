import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  @Input() item: any;
  @Input() caption: any;

  public itemDescription: string;
  public captionLocal: string;

  constructor(
    private modalCtr: ModalController,

  ) { }


  ngOnInit() {
    //console.log(this.caption);

    this.captionLocal = this.caption
    this.itemDescription = this.item;

  }

  checkItem(){


  }

  async closeModal(){



    await this.modalCtr.dismiss();
  }

}
