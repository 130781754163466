import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AddQuestion } from "./question.action";


export class QuestionStateModel{
    questions: any[]| any;

}
@State<QuestionStateModel>({
    name: 'question',
    defaults:{
        questions:[]
    },
})

export class QuestionState{
    @Selector()
    static getQuestions(state:QuestionStateModel ){
        return state.questions
    }

    @Action(AddQuestion)
    add({getState, patchState}: StateContext<QuestionStateModel>, {payload}: AddQuestion){
        const state= getState();
        patchState({
            questions:[...state.questions, payload]
        });
    }
}