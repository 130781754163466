import {
  AddToListUsers,
  SetUserData,
 
  
 
  SetUserId,
} from './../../kiethiktool/state/user/user.action';
import { IUsers } from './../../kiethiktool/interfaces/users';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UsersState } from 'src/app/kiethiktool/state/user/user.state';

@Injectable({
  providedIn: 'root',
})
export class Userservice {
  constructor(private readonly store: Store) {}

  getUserId(): Observable<any> {
    return this.store.select(UsersState.getUserId);
  }
  getAllUsersFromList(): Observable<IUsers[]> {
    return this.store.select(UsersState.getListUsers);
  }

  getUserDataFromState(): Observable<any> {
    return this.store.select(UsersState.getUserData);
  }

  addUserToArray(users: IUsers): Observable<unknown> {
    return this.store.dispatch(new AddToListUsers(users));
  }

  setUserDataToState(user: any): Observable<unknown> {
    return this.store.dispatch(new SetUserData(user));
  }

  setUserIDToState(id: any): Observable<unknown> {
    return this.store.dispatch(new SetUserId(id));
  }

  // setUserDataToClear(): Observable<unknown> {
  //   return this.store.dispatch(new SetUserDataToClear());
  // }
}
