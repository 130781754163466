// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyC_F_zgF-qJRdywuv5ev66uWRGzGLZXSnw",
    authDomain: "kiethiktool.firebaseapp.com",
    projectId: "kiethiktool",
    storageBucket: "kiethiktool.appspot.com",
    messagingSenderId: "649284790897",
    appId: "1:649284790897:web:efd8c36a91377099f209d0",
    measurementId: "G-SQ263418KF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
